/* we are using flaticons in addition to using material-icons */
@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  margin: 0;
  border-radius: 0;
  font-family: "Roboto", "Protest Guerrilla", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* This helps to hide scrollbar shown by calendly iframe  */
.calendly-overlay .calendly-popup {
  max-height: calc(100vh - 60px);
}
